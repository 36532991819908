import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import GridContainer from "../components/Grid/GridContainer"
import GridItem from "../components/Grid/GridItem"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <GridContainer style={{margin: "30px 10px"}} spacing={2}>
      <GridItem style={{textAlign: "center"}}>
        <h1>&#128546; Not Found</h1>
        <h5><br/>Unfortunately you hit a page that may have existed in the past, but no longer exists and we're sorry about that!<br/>
        Feel free to stick around and check out our other content or get wild, create an account and start contributing with others who are passionate about center console boats! &#128521; <br/></h5>
        <small>If you think this is an error, please <Link to={"/contact"}> click here</Link> and let us know and we'll take a look immediately!</small><br/>
      </GridItem>
    </GridContainer>
  </Layout>
)

export default NotFoundPage
